import * as React from 'react';
import { FC } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Adsense from '../components/templates/Adsense';
import Layout from '../components/Layout';
import PostList from '../components/PostList';
import Pagination from '../components/Pagination';

interface Props extends FC {
  data: { allWordpressPost: { edges: [] }};
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    numPages: number;
  }
};

const IndexPage = ({ data, pageContext }: Props) => {

  const { edges: posts } = data.allWordpressPost

  return (
    <Layout>
      <PostList posts={posts} />
      <Pagination pageContext={pageContext} pathPrefix="/" />
      <Ad>
        <Adsense client="ca-pub-9838478459142370" slot="8045017266" format="auto" responsive="true" />
      </Ad>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`;


const Ad = styled.div`
  max-width: 800px;
  margin: 4em auto 3em;
  text-align: center;
`;
