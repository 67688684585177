import * as React from 'react';
import AdSense from 'react-adsense';

type Props = {
  client: string;
  slot: string;
  format: string;
  responsive: string;
};

const Adsense = ({ client, slot, format, responsive = '' }: Props) => (
  <div>
    <AdSense.Google
      client={client}
      slot={slot}
      format={format}
      responsive={responsive}
    />
  </div>
);

export default Adsense;
