import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    numberOfPages: number;
    pageNumber: number;
  };
  pathPrefix?: string;
};

const Pagination = ({ pageContext, pathPrefix }: Props) => {
  const { previousPagePath, nextPagePath, numberOfPages, pageNumber } = pageContext

  if (!previousPagePath && !nextPagePath) { return null; }

  return (
    <Paginate className="pagination is-centered" role="navigation" aria-label="pagination">
      {previousPagePath && (
        <Link to={previousPagePath} className="pagination-previous" rel="prev">
          <FontAwesomeIcon icon={['fas', 'chevron-left']} size="sm" />
        </Link>
      )}
      {nextPagePath && (
        <Link to={nextPagePath} className="pagination-next" rel="next">
          <FontAwesomeIcon icon={['fas', 'chevron-right']} size="sm" />
        </Link>
      )}
      <ul className="pagination-list">
        <li><Link to={pathPrefix} className={`pagination-link${0 === pageNumber ? ' is-current' : ''}`} aria-label="Goto page 1">1</Link></li>
        {numberOfPages > 5 && pageNumber !== 2 && pageNumber !== 1 && pageNumber !== 0 && <li><span className="pagination-ellipsis">&hellip;</span></li>}
        {Array.from({ length: numberOfPages }, (_, i: number) => {
          if (i !== 0 && (i === pageNumber || i - 1 === pageNumber || i + 1 === pageNumber) && i !== numberOfPages - 1) {
            return (
              <Link className={`pagination-link${i === pageNumber ? ' is-current' : ''}`} aria-label={`Goto page ${i + 1}`} key={`pagination-number${i + 1}`}
                to={`${pathPrefix === '/' ? pathPrefix : `${pathPrefix}/`}page/${i === 0 ? "" : i + 1}`}>
                {i + 1}
              </Link>
            );
          }
        })}
        {numberOfPages > 5 && pageNumber !== numberOfPages - 3 && pageNumber !== numberOfPages - 2 && pageNumber !== numberOfPages - 1 && <li><span className="pagination-ellipsis">&hellip;</span></li>}
        <li><Link to={`${pathPrefix === '/' ? pathPrefix : `${pathPrefix}/`}page/${numberOfPages}`} className={`pagination-link${numberOfPages - 1 === pageNumber ? ' is-current' : ''}`} aria-label={`Goto page ${numberOfPages}`}>{numberOfPages}</Link></li>
      </ul>
    </Paginate>
  );
};

export default Pagination;

const Paginate = styled.nav`
  max-width: 480px;
  margin: 0 auto 30px !important;
`;
